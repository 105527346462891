import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import {Autoplay, EffectFlip, Navigation, Pagination, Scrollbar} from 'swiper/modules';
import { Fancybox } from "@fancyapps/ui";
import {resetStoryProgress, surgeryProgress} from "../functions";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

document.querySelectorAll('.gallery-slider').forEach(item => {
    const parent_el = item.closest('.gallery-slider__wrapper');

    new Swiper(item, {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        initialSlide: 0,
        spaceBetween: 0,
        centeredSlides: false,
        loop: true,
        navigation: {
            nextEl: parent_el.querySelector('.gallery-slider__button--next'),
            prevEl: parent_el.querySelector('.gallery-slider__button--prev'),
        },
        pagination: {
            el: parent_el.querySelector('.gallery-slider__pagination'),
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0,
                centeredSlides: false,
            },
            1400: {
                slidesPerView: 1.562,
                spaceBetween: 160,
                centeredSlides: true,
            }
        }
    });
});

document.querySelectorAll('.doctors-slider').forEach(item => {
    const parent_el = item.closest('.doctors-slider__wrapper');

    new Swiper(item, {
        modules: [Navigation, Pagination],
        slidesPerView: 1.1,
        spaceBetween: 16,
        loop: false,
        navigation: {
            nextEl: parent_el.querySelector('.doctors-slider__button--next'),
            prevEl: parent_el.querySelector('.doctors-slider__button--prev'),
        },
        pagination: {
            el: parent_el.querySelector('.doctors-slider__pagination'),
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 1.1,
                spaceBetween: 16,
            },
            830: {
                slidesPerView: 2.58,
                spaceBetween: 24,
            },
            1400: {
                slidesPerView: 4.35,
                spaceBetween: 24,
                loop: true,
                centeredSlides: true,
            },
        },
        on: {
            beforeInit: function (instance) {
                const slides = item.querySelectorAll('.swiper-slide');
                const totalSlides = slides.length;

                if (totalSlides === 4) {
                    for (let i = 0; i < 7 - totalSlides; i++) {
                        const clone = slides[i % totalSlides].cloneNode(true);
                        item.querySelector('.swiper-wrapper').appendChild(clone);
                    }

                    instance.params.loop = true;
                }
            },
            afterInit: function (instance) {
                if (instance.slides.length <= instance.params.slidesPerView) {
                    instance.el.classList.add('doctors-slider--default');
                    instance.allowTouchMove = false;

                    instance.navigation.nextEl.style.display = 'none';
                    instance.navigation.prevEl.style.display = 'none';
                    instance.pagination.el.style.display = 'none';

                    instance.wrapperEl.style.justifyContent = 'flex-end';

                    setTimeout(function () {
                        instance.wrapperEl.style.transform = 'none';
                    }, 500);
                }
            }
        }
    });
});

document.querySelectorAll('.certificate-slider').forEach(item => {
    const parent_el = item.closest('.certificate-slider__wrapper');

    new Swiper(item, {
        modules: [Navigation, Scrollbar],
        slidesPerView: 2.6,
        spaceBetween: 16,
        centeredSlides: true,
        loop: true,
        allowTouchMove: false,
        scrollbar: {
            el: parent_el.querySelector('.swiper-scrollbar'),
            hide: false,
        },
        navigation: {
            nextEl: parent_el.querySelector('.certificate-slider__button--next'),
            prevEl: parent_el.querySelector('.certificate-slider__button--prev'),
        },
        speed: 3000,
        preventClicks: false,
        breakpoints: {
            320: {
                slidesPerView: 1.93,
                spaceBetween: 16,
            },
            830: {
                slidesPerView: 4.34,
                spaceBetween: 16,
            },
            1400: {
                slidesPerView: 7,
                spaceBetween: 16,
            },
            1700: {
                slidesPerView: 9,
                spaceBetween: 16,
            },
        },
        on: {
            beforeInit: function () {
                const slides = item.querySelectorAll('.swiper-slide');
                const totalSlides = slides.length;

                if (totalSlides < 15) {
                    for (let i = 0; i < 15 - totalSlides; i++) {
                        const clone = slides[i % totalSlides].cloneNode(true);
                        item.querySelector('.swiper-wrapper').appendChild(clone);
                    }
                }
            },
            afterInit: function (instance) {
                instance.slides.map((item, index) => {
                    item.addEventListener('click', function () {
                        const clicked_el = item.querySelector('.certificate-slider__slide');
                        const slider_images = instance.slides.map(subitem => {
                            let linkStr = '';

                            if (subitem.querySelector('.certificate-slider__slide').getAttribute('data-link')) {
                                const linkAttrs = parseInt(subitem.querySelector('.certificate-slider__slide').getAttribute('data-target')) === 1 ? 'rel="nofollow noopener noindex" target="_blank"' : '';

                                linkStr = `
                                    <div class="certificate-slider-box__button">
                                        <a href="${subitem.querySelector('.certificate-slider__slide').getAttribute('data-link')}" ${linkAttrs}></a>
                                        
                                        <span>${subitem.querySelector('.certificate-slider__slide').getAttribute('data-button')}</span>             
                                    
                                        <div class="certificate-slider-box__arrows">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                <path d="M19.4241 5.61722C19.3757 5.50014 19.304 5.3904 19.2092 5.29502L19.205 5.29078C19.0242 5.11106 18.7751 5 18.5 5L9.5 5C8.94772 5 8.5 5.44772 8.5 6C8.5 6.55228 8.94772 7 9.5 7L16.0858 7L5.79289 17.2929C5.40237 17.6834 5.40237 18.3166 5.79289 18.7071C6.18342 19.0976 6.81658 19.0976 7.20711 18.7071L17.5 8.41421V15C17.5 15.5523 17.9477 16 18.5 16C19.0523 16 19.5 15.5523 19.5 15V6.00069L19.5 5.997C19.4996 5.8625 19.4727 5.73425 19.4241 5.61722Z" fill="#F8F8F8"/>
                                            </svg>
                                            
                                           <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                <path d="M19.4241 5.61722C19.3757 5.50014 19.304 5.3904 19.2092 5.29502L19.205 5.29078C19.0242 5.11106 18.7751 5 18.5 5L9.5 5C8.94772 5 8.5 5.44772 8.5 6C8.5 6.55228 8.94772 7 9.5 7L16.0858 7L5.79289 17.2929C5.40237 17.6834 5.40237 18.3166 5.79289 18.7071C6.18342 19.0976 6.81658 19.0976 7.20711 18.7071L17.5 8.41421V15C17.5 15.5523 17.9477 16 18.5 16C19.0523 16 19.5 15.5523 19.5 15V6.00069L19.5 5.997C19.4996 5.8625 19.4727 5.73425 19.4241 5.61722Z" fill="#131625"/>
                                            </svg>
                                        </div>
                                    </div>    
                                `;
                            }

                            return {
                                caption: `
                                        <div class="certificate-slider-box">
                                            <p class="certificate-slider-box__title">${subitem.querySelector('.certificate-slider__slide').getAttribute('data-title')}</p>       
                                            
                                            ${linkStr}    
                                        </div>
                                    `,
                                src: subitem.querySelector('.certificate-slider__slide').getAttribute('data-full'),
                            };
                        });

                        Fancybox.show(slider_images, {
                            startIndex: index,
                            Images: {
                                zoom: false,
                            },
                            compact: false,
                            wheel: false,
                            contentClick: false,
                            Toolbar: {
                                display: {
                                    left: [],
                                    middle: [],
                                    right: ["close"],
                                },
                            },
                        });
                    })
                });
            },
            click: function (instance) {

            }
        }
    });
});

document.querySelectorAll('.card-rotate').forEach(card => {
    card.querySelectorAll('.card-rotate__button').forEach(button => {
        button.addEventListener('click', () => {
            const isActive = card.classList.contains('active');

            document.querySelectorAll('.card-rotate').forEach(element => {
                element.classList.remove('active');
                setTimeout(() => element.classList.remove('processing'), 500);
            });

            if (!isActive) {
                card.classList.add('active');
                setTimeout(() => card.classList.add('processing'), 550);
            } else {
                
            }
        });
    });
});

document.querySelectorAll('.clients-slider').forEach(item => {
    const parent_el = item.closest('.clients-slider__wrapper');

    const clients_slider = new Swiper(item, {
        slidesPerView: 1.2,
        initialSlide: 1,
        spaceBetween: 16,
        allowTouchMove: false,
        modules: [Pagination, Navigation],
        centeredSlides: true,
        loop: true,
        pagination: {
            el: parent_el.querySelector('.clients-slider__pagination'),
            clickable: true,
        },
        navigation: {
            nextEl: parent_el.querySelector('.clients-slider__button--next'),
            prevEl: parent_el.querySelector('.clients-slider__button--prev'),
        },
        on: {
            beforeInit: function () {
                const slides = item.querySelectorAll('.clients-slider__slide');
                const totalSlides = slides.length;

                if (totalSlides < 10) {
                    for (let i = 0; i < 10 - totalSlides; i++) {
                        const clone = slides[i % totalSlides].cloneNode(true);
                        item.querySelector('.swiper-wrapper').appendChild(clone);
                    }
                }
            },
            afterInit: function (instance) {
                setTimeout(function () {
                    if (instance.slides[instance.activeIndex].querySelector('.swiper').swiper) {
                        resetStoryProgress(instance.slides[instance.activeIndex].querySelector('.swiper').swiper, instance);
                    }
                }, 700);
            },
            slideChange: function (instance) {
                const videosEl = instance.el.querySelectorAll('video');
                videosEl.forEach(item => {
                    item.pause();
                    item.removeAttribute('playsinline');
                });

                const activeVideoParentEl = instance.slides[instance.activeIndex];
                const activeVideo = activeVideoParentEl.querySelector('video');

                if (activeVideo) {
                    activeVideo.setAttribute('playsinline', 'true');
                    activeVideo.play();
                }

                if (!instance.activeIntervals) {
                    instance.activeIntervals = {};
                    instance.activeIntervals[instance.activeIndex] = [];
                }
                else {
                    instance.activeIntervals[instance.activeIndex] = [];

                    if (instance.activeIntervals[instance.previousIndex]) {
                        instance.activeIntervals[instance.previousIndex].forEach(clearInterval);
                    }
                }

                if (instance.slides[instance.activeIndex].querySelector('.swiper').swiper) {
                    instance.slides[instance.activeIndex].querySelector('.swiper').swiper.slideTo(0);

                    resetStoryProgress(instance.slides[instance.activeIndex].querySelector('.swiper').swiper, instance);
                }
            }
        },
        breakpoints: {
            320: {
                slidesPerView: 1.2,
                spaceBetween: 16,
            },
            830: {
                slidesPerView: 2.65,
                spaceBetween: 12,
            },
            1400: {
                slidesPerView: 4.7,
                spaceBetween: 12,
            },
            1700: {
                slidesPerView: 7.4,
                spaceBetween: 12,
            },
        }
    });

    item.querySelectorAll('.clients-stories').forEach((subitem, k) => {
        subitem.querySelectorAll('.clients-stories__button').forEach(el => el.addEventListener('click', function () {
            if (el.classList.contains('swiper-button-disabled'))
                el.classList.contains('clients-stories__button--prev') ? item.swiper.slidePrev() : item.swiper.slideNext();
        }));

        const stories_slider = new Swiper(subitem, {
            spaceBetween: 2,
            allowTouchMove: false,
            modules: [Pagination, Navigation],
            navigation: {
                nextEl: subitem.querySelector('.clients-stories__button--next'),
                prevEl: subitem.querySelector('.clients-stories__button--prev'),
            },
            pagination: {
                el: subitem.querySelector('.clients-stories__pagination'),
                renderBullet: function (index, className) {
                    return `
                        <span class="${className}">
                            <span class="swiper-pagination-bullet__progress"></span>
                        </span>
                    `;
                },
            },
            on: {
                slideChange: function (instance) {
                    resetStoryProgress(instance, clients_slider);
                }
            }
        });
    });
});

document.querySelectorAll('.surgery-slider').forEach(item => {
    const parent_el = item.closest('.surgery-slider__wrapper');

    new Swiper(item, {
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: false,
        pagination: {
            el: parent_el.querySelector('.swiper-pagination'),
            renderBullet: function (index, className) {
                return `
                        <span class="${className}">
                            <span class="swiper-pagination-bullet__progress"></span>
                        </span>
                    `;
            },
        },
        on: {
            afterInit: function (instance) {
                surgeryProgress(instance);
            },
            slideChange: function (instance) {
                surgeryProgress(instance);
            }
        }
    });
});

document.querySelectorAll('.marque-doctors-slider').forEach(item => {
    new Swiper(item, {
        modules: [Autoplay],
        slidesPerView: 1.5,
        spaceBetween: 0,
        speed: 2000,
        allowTouchMove: false,
        loop: true,
        autoplay: {
            delay: 1,
            disableOnInteraction: false,
        },
        on: {
            beforeInit: function () {
                const slides = item.querySelectorAll('.swiper-slide');
                const totalSlides = slides.length;

                if (totalSlides < 6) {
                    for (let i = 0; i < 6 - totalSlides; i++) {
                        const clone = slides[i % totalSlides].cloneNode(true);
                        item.querySelector('.swiper-wrapper').appendChild(clone);
                    }
                }
            },
        },
        breakpoints: {
            320: {
                slidesPerView: 1.5,
            },
            830: {
                slidesPerView: 3.2,
            },
            1700: {
                slidesPerView: 4.2,
            },
        }
    });
});

document.querySelectorAll('.examples-slider').forEach(item => {
    const parent_el = item.closest('.examples-slider__wrapper');

    new Swiper(item, {
        modules: [Autoplay, Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: false,
        autoHeight: true,
        loop: true,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: parent_el.querySelector('.swiper-button-next'),
            prevEl: parent_el.querySelector('.swiper-button-prev'),
        },
        pagination: {
            el: parent_el.querySelector('.swiper-pagination'),
        },
        on: {
            beforeInit: function () {
                const slides = item.querySelectorAll('.swiper-slide');
                const totalSlides = slides.length;

                if (totalSlides < 5) {
                    for (let i = 0; i < 5 - totalSlides; i++) {
                        const clone = slides[i % totalSlides].cloneNode(true);
                        item.querySelector('.swiper-wrapper').appendChild(clone);
                    }
                }
            },
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            830: {
                slidesPerView: 1.37,
                centeredSlides: true,
                spaceBetween: 48,
            },
            1200: {
                slidesPerView: 1.75,
                centeredSlides: true,
                spaceBetween: 48,
            },
            1700: {
                slidesPerView: 2.01,
                centeredSlides: true,
                spaceBetween: 160,
            },
        }
    });
});