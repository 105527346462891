const photo_telegram_el = document.querySelector('#photo_telegram');

if (photo_telegram_el && window.innerWidth <= 1023) {
    window.addEventListener('scroll', function () {
        const rect = photo_telegram_el.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        if (rect.top >= -50 && rect.bottom <= windowHeight + 50) {
            photo_telegram_el.classList.add('active');
        } else {
            photo_telegram_el.classList.remove('active');
        }
    });
}