export function isSafari() {
    const ua = navigator.userAgent;

    return /^((?!chrome|android|crios|fxios|edgios).)*safari/i.test(ua);
}

export function resetStoryProgress (instance, clients_slider) {
    const parentSliderIndex = clients_slider.activeIndex;
    const pagination_bullets = instance.pagination.bullets;
    const active_index = instance.activeIndex;

    let width = 0;
    let intervalId = null;

    pagination_bullets.forEach((el, i) => {
        if (i < active_index) {
            el.classList.add('full');
            el.querySelector('.swiper-pagination-bullet__progress').classList.add('swiper-pagination-bullet__progress--full');
        } else {
            el.classList.remove('full');
            el.querySelector('.swiper-pagination-bullet__progress').classList.remove('swiper-pagination-bullet__progress--full');
            el.querySelector('.swiper-pagination-bullet__progress').style.width = '0%';
        }
    });

    intervalId = setInterval(() => {
        if (width < 100) {
            width += 1;
            pagination_bullets[active_index].querySelector('.swiper-pagination-bullet__progress').style.width = `${width}%`;
        } else {
            clearInterval(intervalId);

            if (instance.isEnd) {
                clients_slider.slideNext();
            }
            else {
                instance.slideNext();
            }
        }
    }, 100);

    clients_slider.activeIntervals[parentSliderIndex].forEach(clearInterval);
    clients_slider.activeIntervals[parentSliderIndex] = [intervalId];
}

export function surgeryProgress (instance) {
    const pagination_bullets = instance.pagination.bullets;
    const active_index = instance.activeIndex;

    let width = 0;
    let intervalId = null;

    pagination_bullets.forEach((el, i) => {
        if (i < active_index) {
            el.classList.add('full');
            el.querySelector('.swiper-pagination-bullet__progress').classList.add('swiper-pagination-bullet__progress--full');
        } else {
            el.classList.remove('full');
            el.querySelector('.swiper-pagination-bullet__progress').classList.remove('swiper-pagination-bullet__progress--full');
            el.querySelector('.swiper-pagination-bullet__progress').style.width = '0%';
        }
    });

    intervalId = setInterval(() => {
        if (width < 100) {
            width += 1;
            pagination_bullets[active_index].querySelector('.swiper-pagination-bullet__progress').style.width = `${width}%`;
        } else {
            clearInterval(intervalId);

            if (instance.isEnd) {
                instance.slideTo(0);
            }
            else {
                instance.slideNext();
            }
        }
    }, 100);
}

export function setCookie(name, value, days) {
    let expires = "";

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');

    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}
