document.addEventListener('DOMContentLoaded', function () {
    const button_up_el = document.querySelector('.button--up');

    if (button_up_el) {
        button_up_el.addEventListener('click', function () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });

        window.addEventListener('scroll', function () {
            if (window.scrollY > 200) {
                button_up_el.style.display = 'flex';
            }
            else {
                button_up_el.style.display = 'none';
            }
        }, {
            passive: true
        });
    }
});
