import 'perfect-scrollbar/css/perfect-scrollbar.css';
import PerfectScrollbar from 'perfect-scrollbar';
import {isSafari} from "../functions";

document.addEventListener('DOMContentLoaded', () => {
    const scrollableElements = document.querySelectorAll('.scrollable-list');
    scrollableElements.forEach(element => {
        const ps = new PerfectScrollbar(element, {
            wheelSpeed: 0.21,
            wheelPropagation: false,
            minScrollbarLength: 20,
            suppressScrollX: true
        });
    });

    if (isSafari())
        document.querySelectorAll('.attachment-section__image').forEach(item => item.classList.add('attachment-section__image--safari'));
});
