import axios from "axios";

document.body.addEventListener('click', function(event) {
    if (event.target.classList.contains('button--more') || event.target.closest('.button--more')) {
        const button_el = event.target.classList.contains('button--more') ? event.target : event.target.closest('.button--more');
        button_el.setAttribute('disabled', 'true');

        axios.get(api_settings.ajax_url, {
            params: {
                nonce: api_settings.nonce,
                action: 'update_blog',
                paged: button_el.getAttribute('data-next-page'),
            }
        })
            .then(function (response) {
                if (!response.data.is_next_page) {
                    button_el.style.display = 'none';
                }
                else {
                    button_el.setAttribute('data-next-page', response.data.next_page);
                }

                document.querySelector('#html_posts').insertAdjacentHTML('beforeend', response.data.posts_html);

                const path = window.location.pathname;
                const segments = path.split('/');
                const slug = segments[1];

                history.pushState(null, '', `/${slug}/page/${button_el.getAttribute('data-next-page')}/`);

                button_el.removeAttribute('disabled');
            })
            .catch(function (error) {
                console.log(error);
                button_el.removeAttribute('disabled');
            })
    }
});

document.querySelectorAll('.block-more__button').forEach(item => item.addEventListener('click', function () {
    const parent_el = item.closest('.block-more');
    const content_el = parent_el.querySelector('.block-more__child');
    const content_hidden_el = parent_el.querySelector('.block-more__text');

    content_el.classList.toggle('active');
    content_hidden_el.style.maxHeight = content_el.classList.contains('active') ? `${content_hidden_el.scrollHeight}px` : 0;
    item.textContent = content_el.classList.contains('active') ? item.getAttribute('data-less') : item.getAttribute('data-more');
}));