document.querySelectorAll('.prices-tab').forEach(item => item.addEventListener('click', function () {
    if (this.classList.contains('prices-tab--active'))
        return;

    document.querySelectorAll('.prices-tab').forEach(subitem => subitem.classList.remove('prices-tab--active'));

    this.classList.add('prices-tab--active');
    const city_name = this.getAttribute('data-city');

    document.querySelectorAll('.prices-table [data-city]').forEach(subitem => subitem.style.display = subitem.getAttribute('data-city') === city_name ? 'revert' : 'none');
}));

document.querySelectorAll('.faq-item__header').forEach(item => item.addEventListener('click', function () {
    const parent_el = item.closest('.faq-item');
    const content_el = parent_el.querySelector('.faq-item__content');

    parent_el.classList.toggle('faq-item--active');

    if (parent_el.classList.contains('faq-item--active')) {
        content_el.style.maxHeight = `${content_el.scrollHeight}px`;
    }
    else {
        content_el.style.maxHeight = 0;
    }
}));